import axios from 'axios';

const TSAPI = axios.create({
    baseURL: process.env.GATSBY_API_URL,
    timeout: 50000,
    headers: {
        Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`
    }
});

export default TSAPI