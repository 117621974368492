import React, { useEffect } from 'react';

const options = {
  autoConfig: true, 	// set pixel's autoConfig
  debug: false, 		// enable logs
};

const FacebookPixel = ({ children }) => {
  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(818859032317965, options);
        ReactPixel.pageView();
      })
      .catch(() => { });
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

export default FacebookPixel;